@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

body {
    margin: 0;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--theme-ui-colors-base-black);
    min-height: 100vh;
    color: var(--theme-ui-colors-type-light);
    background-image: url(../public/images/page-background.png);
    background-position: center;
}

button {
    cursor: pointer;
}

.ReactModalPortal {
    z-index: 9999;
    position: relative;
}

.get-report-form {
    label {
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;
        color: white;
        margin-bottom: 8px;
        font-family: Montserrat;
    }
}

input[type="checkbox"] {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid;
    border-color: #CECFD2;
    position: relative;
    appearance: none;
}

input[type="checkbox"]:checked {
    background-color: #EF7808;
    border-color: #EF7808;
}

input[type="checkbox"]:checked::before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 10px;
    border: 2px solid transparent;
    border-bottom-color: white;
    border-right-color: white;
    position: absolute;
    bottom: 7px;
    left: 7px;
    transform: rotate(45deg);
}
